import { FormControl, TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import TelegramIcon from "@mui/icons-material/Telegram";
import { send_sms_to_member_api } from "src/DAL/member/Member";

export default function SMSMessage({ setOpen, contact_number }) {
  const [inputs, setInputs] = useState({ message: "" });
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const postData = {
      to: "+" + contact_number,
      body: inputs.message,
    };
    const result = await send_sms_to_member_api(postData);
    if (result.code === 200) {
      enqueueSnackbar(result.message, { variant: "success" });
      setIsLoading(false);
      setOpen(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="container">
        <form className="row" onSubmit={handleSubmit}>
          <div className="col-lg-12 col-md-6 col-sm-12 mt-3">
            <FormControl fullWidth>
              <TextField
                id="outlined-multiline-flexible"
                label="Message"
                multiline
                rows={4}
                name="message"
                value={inputs.message}
                onChange={(e) => handleChange(e)}
                required
              />
            </FormControl>
          </div>

          <div class="col-12 mt-3">
            <button class="small-contained-button w-100">
              {isLoading ? "Sending..." : "Send"}
              <TelegramIcon className="ms-2" />
            </button>
          </div>
        </form>
      </div>
    </>
  );
}
