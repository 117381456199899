import React, { useEffect, useState } from "react";
import { Avatar, IconButton, Tooltip } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { s3baseUrl } from "src/config/config";
import { useSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { dummyImage } from "src/assets";
import MessageIcon from "@mui/icons-material/Message";
import { Icon } from "@iconify/react";
import { styled } from "@mui/material/styles";
import { memberDetailApi } from "src/DAL/member/Member";
import Badge from "@mui/material/Badge";
import TotalCoinsCount from "src/components/memberProfile/TotalCoinsCount";
import SourceSession from "src/components/memberProfile/SourceSession";
import AppBugReportsMission from "src/components/_dashboard/app/AppBugReportsMission";
import { PaiChart } from "src/components/pie-chart/PaiChart";
import DashboardAssessment from "src/components/memberProfile/DashboardAssessment";
import ChartJs from "./Chart";
import moment from "moment";
import MembersSubscriptionList from "./MembersSubscriptionList";
import LessonQuestionAssessment from "src/components/memberProfile/LessonQuestionAssessment";
import NintyDayQuestions from "src/components/memberProfile/NintyDayQuestions";
import GeneralCalendar from "src/components/GeneralComponents/GeneralCalendar";
import { useChat } from "src/Hooks/ChatContext/ChatContext";
import { isValidNumber } from "libphonenumber-js";
import {
  difference_between_two_dates,
  get_view_dates,
} from "src/utils/constants";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import WhatsappPopUpModel from "src/components/GeneralComponents/WhatsappPopUpModel";
import { useContentSetting } from "src/Hooks/ContentSettingState";
import AppWebsiteVisits from "src/components/IntentionChart/AppWebsiteVisits";
import parsePhoneNumber from "libphonenumber-js";
import SMSMessage from "./SMSMessage";
import SmsIcon from "@mui/icons-material/Sms";

const useStyles = makeStyles(() => ({
  loading: {
    marginLeft: "50%",
    marginTop: "20%",
  },
}));

const MemberProfile = () => {
  const member_id = useParams();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { handleAddChat } = useChat();
  const { userInfo } = useContentSetting();
  const [isLoading, setIsLoading] = useState(true);
  const [eventsList, setEventList] = useState([]);
  const [memberDetail, setMemberDetail] = useState();
  const [coinsCount, setCoinsCount] = useState([]);
  const [goalQuestion, setGoalQuestion] = useState([]);
  const [days, setDays] = React.useState("");
  const { pathname } = useLocation();
  const [openSMS, setOpenSMS] = useState(false);
  const [earningList, setEarningList] = React.useState([]);
  const [wheelOfLifeStatment, setwheelOfLifeStatment] = useState("");
  const [subscriberList, setSubscriberList] = React.useState([]);
  const [startDate, setStartDate] = React.useState("");
  const [chartDynamicData, setChartDynamicData] = useState([]);
  const [lessonsQuestion, setLessonsQuestion] = useState([]);
  const [isCalenderLoading, setIsCalenderLoading] = useState(true);
  const [defaultTimeValue, setDefaultTimeValue] = useState("");
  const [eventQuestion, setEventQuestion] = useState([]);
  const [currentDate, setCurrentDate] = useState(get_view_dates("month"));
  const [TargetToAchieve, setTargetToAchieve] = useState("");
  const [graphData, setGraphData] = useState([]);
  const [graphLabels, setGraphLabels] = useState([]);
  const [dailyDynamiteStartDate, setDailyDynamiteStartDate] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [questions, setQuestions] = useState([]);
  const { isAccess } = useContentSetting();
  const is_path_includes = (path) => {
    return pathname.toString().includes(path);
  };

  let path_name = "/members";
  if (is_path_includes("/nurture-members")) {
    path_name = "/nurture-members";
  } else if (is_path_includes("/all-members")) {
    path_name = "/all-members";
  } else if (is_path_includes("/leads-center")) {
    path_name = "/leads-center";
  }

  const memberDetailInfo = async () => {
    let apiData = {
      start_date: moment(currentDate.start_date).format("YYYY-MM-DD"),
      end_date: moment(currentDate.end_date).format("YYYY-MM-DD"),
    };
    const difference = difference_between_two_dates(
      apiData.start_date,
      apiData.end_date,
      "days"
    );

    if (difference < 10) {
      setIsCalenderLoading(true);
    }
    const result = await memberDetailApi(member_id.id, apiData);
    if (result.code == 200) {
      setDailyDynamiteStartDate(
        moment(result.first_dynamite_diary_date, "DD-MM-YYYY").format(
          "MM/DD/YYYY"
        )
      );

      result.list_dynamite_diary.forEach((value) => {
        let total = value.completed_intention + value.incomplete_intention;
        const percentage = total
          ? (value.completed_intention / total) * 100
          : 0;
        graphData.push(percentage?.toFixed(2));
        graphLabels.push(moment(value.date, "DD-MM-YYYY").format("MM/DD/YYYY"));
      });
      setQuestions(result.ninety_day_questions_list);
      setEventList(result.event);
      setMemberDetail(result.member);
      setwheelOfLifeStatment(
        result?.wheel_of_life_setting?.wheel_of_life_intention_statement
      );
      setGoalQuestion(result.question_answer_list);
      let coins_count = [
        {
          name: "Daily Dynamite",
          days_count: result.dynamite_diary_count,
          coins_title: "Total Coins",
          remaining_time: "00:00",
          type: "growth_tools",
          coins_count: result.member?.dynamite_diary_coins_count,
          is_completed: result.member?.is_dynamite_diary_added,
          hours_reminder: result.member?.hours_reminder_daily_dynamite,
        },
        {
          name: "Gratitude Daily",
          days_count: result.dynamite_gratitude_count,
          coins_title: "Total Coins",
          remaining_time: "00:00",
          type: "growth_tools",
          coins_count: result.member?.dynamite_gratitude_coins_count,
          is_completed: result.member?.is_dynamite_gratitude_added,
          hours_reminder: result.member?.hours_reminder_daily_gratitude,
        },
        {
          name: "Meditation",
          days_count: result.dynamite_meditation_count,
          coins_title: "Total Coins",
          remaining_time: "00:00",
          type: "growth_tools",
          coins_count: result.member?.meditation_coins_count,
          is_completed: result?.is_dynamite_mediation_added,
          hours_reminder: result?.hours_reminder_daily_meditation,
        },
      ];

      setDefaultTimeValue(result.admin_time_zone);
      setCoinsCount(coins_count);
      setEarningList(result.memberEarning);
      setTargetToAchieve(result.target_amount);
      setTotalAmount(result.total_earning);
      setSubscriberList(result?.subscribers);
      setLessonsQuestion(result.lesson_questionnaire_array);
      setEventQuestion(result.dynamite_event_video_questionnaire_array);
      setStartDate(
        moment(result.ninteen_day_vision_start_date).format("MM/DD/YYYY")
      );
      const start_date = moment(result.ninteen_day_vision_start_date).format(
        "MM/DD/YYYY"
      );

      // chart dynamic value
      let counting = 0;
      const array_of_earning = [];
      const ChartPoints = [];
      const array_of_days = [];
      const reverseEarning = [];
      const demoXYValues = [];
      let LastDateObject = "";
      if (result.memberEarning.length > 0) {
        LastDateObject = moment(result.memberEarning[0].date).format(
          "MM/DD/YYYY"
        );
      }

      if (LastDateObject > startDate) {
        result?.memberEarning.reverse().forEach((earning) => {
          reverseEarning.push(earning.earning); // needs to reverse
          const date = moment(earning.date).format("MM/DD/YYYY");
          counting = counting + earning.earning;
          const dateCheck = dateDifference(start_date, date);
          array_of_days.push(dateDifference(start_date, date));
          array_of_earning.push(counting);
          ChartPoints.push(dateDifference(start_date, date));
          demoXYValues.push({
            x: dateCheck < 0 ? 1 : dateCheck + 1,
            y: counting,
          });
        });
      } else {
      }
      setChartDynamicData(demoXYValues);
      setIsLoading(false);
      setIsCalenderLoading(false);
    } else {
      setIsLoading(false);
      setIsCalenderLoading(false);
    }
  };

  const handleSendMessage = async (row) => {
    handleAddChat(memberDetail?._id);
  };

  const handleSendSMS = async () => {
    setOpenSMS(true);
  };

  const handleNavigateLessonQuestion = (value) => {
    navigate(
      `${path_name}/lessons-question/answers?member_id=${member_id.id}&created_for_id=${value._id}&created_for=${value?.created_for}`
    );
  };

  const handleWhatAppMessage = () => {
    if (!isValidNumber(formatPhone())) {
      enqueueSnackbar("Invalid contact number", { variant: "error" });
      return;
    }
    navigate(`/whatsapp-chat/${member_id.id}`);
  };

  const get_graph_data = () => {
    const colors = memberDetail?.wheel_of_life?.map((q) => q?.scaling_color);
    const labels = memberDetail?.wheel_of_life?.map(
      (q) => q?.scaling_main_heading
    );
    const data = memberDetail?.wheel_of_life?.map((q) =>
      q?.answer ? q?.answer : 0
    );
    return { colors, labels, data };
  };

  const dateDifference = (startDate, lastDate) => {
    const date1 = new Date(startDate);
    const date2 = new Date(lastDate);
    const Difference_In_Time = date2.getTime() - date1.getTime();
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    const NinetyDay = Difference_In_Days;
    return NinetyDay;
  };

  const StyledBadge = styled(Badge)(({ theme, is_online }) => ({
    "& .MuiBadge-badge": {
      backgroundColor: is_online.is_online === true ? "#44b700" : "#f6bd4b",
      color: is_online.is_online === true ? "#44b700" : "#f6bd4b",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "3px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));

  useEffect(() => {
    memberDetailInfo();
  }, [currentDate]);

  useEffect(() => {
    let days = [];
    for (let index = 1; index <= 90; index++) {
      days.push(index);
    }
    setDays(days);
  }, []);

  const formatPhone = () => {
    let contact_number = memberDetail.contact_number;
    if (contact_number.startsWith("+")) return contact_number;
    return `+${memberDetail.contact_number}`;
  };

  if (isLoading === true) {
    return <CircularProgress className={classes.loading} color="primary" />;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-12 col-md-10 mb-4">
          <IconButton
            className="back-screen-button back-buuton-profile pt-2"
            onClick={() => navigate(-1)}
          >
            <ArrowBackIcon />
          </IconButton>
          <>
            <Tooltip
              key={1}
              title={memberDetail.is_online === false ? "Offline" : "Online"}
              arrow
            >
              <StyledBadge
                overlap="circular"
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                is_online={{ is_online: memberDetail.is_online }}
                variant="dot"
              >
                <Avatar
                  alt={memberDetail.first_name[0]}
                  src={
                    memberDetail.profile_image
                      ? s3baseUrl + memberDetail.profile_image
                      : dummyImage
                  }
                />
              </StyledBadge>
            </Tooltip>
            <span className="mt-3 ms-2">
              {memberDetail.contact_number ? (
                <b>
                  {memberDetail.first_name +
                    " " +
                    memberDetail.last_name +
                    " (" +
                    memberDetail.email +
                    ")" +
                    " (" +
                    memberDetail.contact_number +
                    ")"}
                </b>
              ) : (
                <b>
                  {" "}
                  {memberDetail.first_name +
                    " " +
                    memberDetail.last_name +
                    " (" +
                    memberDetail.email +
                    ")"}
                </b>
              )}
            </span>
          </>
        </div>
        <div className="col-12 col-md-2 text-end mb-4 chat-icon-container">
          {isValidNumber(formatPhone()) ? (
            <Tooltip title={"WhatsApp Message"} arrow>
              <span
                className="anchor-style action-box-style whatsapp-style me-2"
                onClick={handleWhatAppMessage}
              >
                <WhatsAppIcon />
              </span>
            </Tooltip>
          ) : (
            ""
          )}
          {isAccess.is_chat_allowed && (
            <Tooltip title={"Chat"} arrow>
              <span
                className="anchor-style action-box-style chat-style me-2"
                onClick={handleSendMessage}
              >
                <MessageIcon />
              </span>
            </Tooltip>
          )}
          {isAccess.allow_to_send_sms && isValidNumber(formatPhone()) && (
            <Tooltip title={"SMS"} arrow>
              <span
                className="anchor-style action-box-style sms-style"
                onClick={handleSendSMS}
              >
                <Icon icon="fa-solid:sms" width="25" height="25" />
              </span>
            </Tooltip>
          )}
        </div>
      </div>
      <div className="col-12 mb-4">
        <h2 className="text-center mb-5">
          Welcome To {memberDetail.first_name + " " + memberDetail.last_name}'s
          Profile
        </h2>
      </div>
      {/* new Ui  */}
      <div className="row">
        <div className="col-12 col-md-8">
          <div className="row">
            <div className="col-12 col-md-6 mb-4 d-flex">
              <TotalCoinsCount
                title="Total Coins Count"
                coins_count={memberDetail?.coins_count}
              />
            </div>

            {coinsCount.map((item) => {
              return (
                <div className="col-12 col-md-6 mb-4 d-flex">
                  <AppBugReportsMission data={item} />
                </div>
              );
            })}
          </div>
        </div>
        <div className="col-12 col-md-4 profile-cards sideCard">
          <LessonQuestionAssessment
            sourceSessions={lessonsQuestion}
            name={memberDetail.first_name + " " + memberDetail.last_name}
            title="Lesson Questions"
            onclick={handleNavigateLessonQuestion}
          />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12 col-md-8">
          <div className="mb-4 d-flex profile-cards">
            <PaiChart
              assessment_data={get_graph_data()}
              nameData={memberDetail}
            />
          </div>
        </div>

        <div
          className="col-12 col-md-4 profile-cards"
          style={{ height: "400px", overflowY: "scroll" }}
        >
          <DashboardAssessment
            sourceSessions={memberDetail?.assessment}
            name={memberDetail.first_name + " " + memberDetail.last_name}
            title="Wheel Of Life Assessment"
            WheelOfLifeQuestions={memberDetail?.wheel_of_life}
            intention_statement={memberDetail?.intention_statement}
            wheelOfLifeStatment={wheelOfLifeStatment}
          />
        </div>
      </div>
      {/* to do tracker */}

      <div className="row mt-4">
        <div className="col-12 col-md-8">
          <div className="mb-4 profile-cards">
            <ChartJs
              days={days}
              ChartDataList={earningList}
              chartDynamicData={chartDynamicData}
              TargetToAchieve={TargetToAchieve}
              totalAmount={totalAmount}
            />
          </div>
        </div>

        <div
          className="col-12 col-md-4 profile-cards"
          style={{ height: "400px", overflowY: "scroll" }}
        >
          <NintyDayQuestions
            sourceSessions={questions}
            title="90 Day Questions"
          />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12 col-md-12">
          <div className="mb-4 ">
            <MembersSubscriptionList subscriberList={subscriberList} />
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12 col-md-6">
          <div className="profile-cards">
            <SourceSession sourceSessions={goalQuestion} />
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="profile-cards">
            <LessonQuestionAssessment
              sourceSessions={eventQuestion}
              name={memberDetail.first_name + " " + memberDetail.last_name}
              title="Event Questions"
              onclick={handleNavigateLessonQuestion}
            />
          </div>
        </div>
      </div>

      {/* chart intention_statement start */}
      <div className="row">
        <div className="col-12 mb-3">
          <AppWebsiteVisits
            graphData={graphData}
            graphLabels={graphLabels}
            startDate={dailyDynamiteStartDate}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12 mb-4">
          <GeneralCalendar
            eventList={eventsList}
            setCurrentDate={setCurrentDate}
            showAddEvent={false}
            time_zone={defaultTimeValue}
            currentDate={currentDate}
            isLoading={isCalenderLoading}
          />
        </div>

        <WhatsappPopUpModel
          open={openSMS}
          setOpen={setOpenSMS}
          title={"SMS Message"}
          componentToPassDown={
            <SMSMessage
              setOpen={setOpenSMS}
              contact_number={memberDetail.contact_number}
            />
          }
        />
      </div>
    </div>
  );
};

export default MemberProfile;
