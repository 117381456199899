import { Icon } from "@iconify/react";
import bellFill from "@iconify/icons-eva/bell-fill";
// material
import { alpha } from "@mui/material/styles";
import { Badge, IconButton } from "@mui/material";
import SmsIcon from "@mui/icons-material/Sms";
import { useNavigate } from "react-router";
import MessageIcon from "@mui/icons-material/Message";

// ----------------------------------------------------------------------

export default function ChatButton({ setOpen, open }) {
  const navigate = useNavigate();
  const navigation = () => {
    navigate("/chat");
  };
  return (
    <>
      <IconButton
        size="large"
        color={open ? "primary" : "default"}
        className="notification-icons-btn"
        onClick={navigation}
        sx={{
          ...(open && {
            bgcolor: (theme) =>
              alpha(
                theme.palette.primary.main,
                theme.palette.action.focusOpacity
              ),
          }),
        }}
      >
        <Badge color="error">
          <MessageIcon
            width={20}
            height={20}
            style={{ height: "20px", width: "auto" }}
          />
        </Badge>
      </IconButton>
    </>
  );
}
